import '../styles/globals.css'
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../../next-i18next.config.js';
import { ToastContainer } from 'react-toastify';
import Script from "next/script";

function App({ Component, pageProps }) {
  return (
    <>
      <Component {...pageProps} />
      <ToastContainer />

      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=G-73MRL8JMK5`}
      />

      <Script strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-73MRL8JMK5', {
          page_path: window.location.pathname,
          });
        `}
      </Script>

      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=AW-11420798508`}
      />

      <Script strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11420798508', {
          page_path: window.location.pathname,
          });
        `}
      </Script>

      <Script strategy="lazyOnload">
        {`
            gtag('event', 'conversion', {'send_to': 'AW-11420798508/BhDyCLSDmPkYEKyc7sUq'});
        `}
      </Script>
    </>
  )
}

export default appWithTranslation(App, nextI18NextConfig);
